<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!--요청부서-->
          <c-dept
            type="search"
            label="LBL0010272"
            :isFirstValue="false"
            name="requestDeptCd"
            v-model="searchParam.requestDeptCd">
          </c-dept>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!--검토부서-->
          <c-dept
            type="search"
            label="LBL0010273"
            name="reviewDeptCd"
            :isFirstValue="false"
            v-model="searchParam.reviewDeptCd">
          </c-dept>
        </div>
      </template>
    </c-search-box>
    <!-- 인허가 요청 및 검토 -->
    <c-table
      ref="table"
      title="LBL0010274"
      :columns="grid.columns"
      :data="grid.data"
      :merge="grid.merge"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline>
          <!--인허가 요청-->
          <c-btn label="LBL0010275" v-if="editable" icon="add" @btnClicked="linkClick" />
          <!--검색-->
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: "license-request",
  data() {
    return {
      searchParam: {
        plantCd: null,
        requestDeptCd: null,
        reviewDeptCd: null,
        licenseRequestName: '',
      },
      grid: {
        merge: [
          { index: 0, colName: 'limLicenseRequestId' },
          { index: 1, colName: 'limLicenseRequestId' },
          { index: 7, colName: 'limLicenseRequestId' },
          { index: 8, colName: 'limLicenseRequestId' },
          { index: 9, colName: 'limLicenseRequestId' },
          { index: 10, colName: 'limLicenseRequestId' },
          { index: 11, colName: 'limLicenseRequestId' },
          { index: 12, colName: 'limLicenseRequestId' },
        ],
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            //사업장
            label: 'LBLPLANT',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'licenseRequestName',
            field: 'licenseRequestName',
            //인허가 요청명
            label: 'LBL0010276',
            align: 'left',
            type: 'link',
            style: 'width:250px',
            sortable: true,
          },
          {
            // 인허가종류별 검토현황
            label: 'LBL0010277',
            align: 'center',
            child: [
              {
                name: 'limLicenseKindStatusName',
                field: 'limLicenseKindStatusName',
                //검토상태
                label: 'LBL0010278',
                align: 'center',
                style: 'width:120px',
                sortable: false,
              },
              {
                name: 'relatedLawsName',
                field: 'relatedLawsName',
                //관련법규
                label: 'LBL0010245',
                align: 'center',
                style: 'width:200px',
                sortable: false,
              },
              {
                name: 'limLicenseKindName',
                field: 'limLicenseKindName',
                //인허가종류
                label: 'LBL0010249',
                align: 'left',
                style: 'width:250px',
                sortable: false,
              },
              {
                name: 'reviewDeptName2',
                field: 'reviewDeptName2',
                // 검토부서
                label: 'LBL0010273',
                align: 'center',
                style: 'width:120px',
                sortable: false,
              },
              {
                name: 'reviewUserName2',
                field: 'reviewUserName2',
                // 검토부서
                label: 'LBL0010273',
                align: 'center',
                style: 'width:120px',
                sortable: false,
              },
            ]
          },
          {
            name: 'completeRequestDate',
            field: 'completeRequestDate',
            //완료 요청일
            label: 'LBL0010279',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'requestStepName',
            field: 'requestStepName',
            //전체 진행 단계
            label: 'LBL0010280',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'requestDeptName',
            field: 'requestDeptName',
            // 인허가요청부서
            label: 'LBL0010281',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'requestUserName',
            field: 'requestUserName',
            // 인허가요청자
            label: 'LBL0010282',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'reviewDeptName',
            field: 'reviewDeptName',
            // 인허가검토부서
            label: 'LBL0010283',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'reviewUserName',
            field: 'reviewUserName',
            // 인허가검토자
            label: 'LBL0010284',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: "",
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        isFull: true,
        top: "",
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.lim.lincense.request.list.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.popupOptions.title = 'LBL0010274'; // 인허가 요청 및 검토
      this.popupOptions.param = {
        stepCd: row ? row.requestStepCd : '',
        limLicenseRequestId: row ? row.limLicenseRequestId : '',
      };
      this.popupOptions.target = () => import(`${"./licenseRequestStep.vue"}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  },
};
</script>
